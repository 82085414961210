import { Cookie } from '@testavivadk/utilities'
import { authCookieName } from '@testavivadk/common-tools/constants'

export function getAuthToken(): string {
  let token = ''
  const cookie = Cookie.get(authCookieName)

  if (cookie != null) {
    token = JSON.parse(cookie).token
  }

  return token
}

export * from './AcceptedFileExtensions'
export * from './lawdocs'
export * from './formatDate'
export { logoutUser } from './user'
export { getOrderProductTypes, getOrderPurchaseTypes } from './order'
