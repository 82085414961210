import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from '@store/middleware'

import type {
  BookingsResponseType,
  IAvailability,
  IUserDetails,
  UpdateBookingRequestType,
  CreateBookingType,
} from '@testavivadk/common-tools/types'
import { bookingUrl, authUserType } from '@constants'

export const BOOKING_API_REDUCER_KEY = 'bookingApi'

export const bookingApi = createApi({
  reducerPath: BOOKING_API_REDUCER_KEY,
  tagTypes: ['BOOKINGS'],
  baseQuery: (args, api, extraOptions) =>
    baseQueryWithAuth({ baseUrl: bookingUrl })(args, api, {
      ...extraOptions,
      isExternalService: true,
    }),
  endpoints: (builder) => ({
    getBookings: builder.query<BookingsResponseType, { userId: IUserDetails['id']; statuses?: string }>({
      query: ({ userId, statuses }) => ({
        url: `/api/bookings?user_id=${userId}${statuses != null ? `&status=${statuses}` : ''}`,
        method: 'GET',
        headers: { [authUserType]: 'user' },
      }),
      providesTags: ['BOOKINGS'],
    }),
    putBooking: builder.mutation<
      IAvailability,
      {
        user_id: IUserDetails['id']
        data: UpdateBookingRequestType
      }
    >({
      query: ({ user_id, data: { bookingId, ...rest } }) => ({
        url: `/api/bookings/${bookingId}`,
        method: 'PUT',
        body: { ...rest.payload, user_id },
        headers: { [authUserType]: 'user' },
      }),
      invalidatesTags: ['BOOKINGS'],
    }),
    createBooking: builder.mutation<
      IAvailability,
      {
        user_id: IUserDetails['id']
        data: CreateBookingType
      }
    >({
      query: ({ user_id, data }) => ({
        url: `/api/bookings`,
        method: 'POST',
        body: { ...data, user_id },
        headers: { [authUserType]: 'user' },
      }),
      invalidatesTags: ['BOOKINGS'],
    }),
    getBookingPaymentLink: builder.query<unknown, number>({
      query: (id) => ({
        url: `/api/bookings/${id}/payment`,
        method: 'GET',
        headers: { [authUserType]: 'user' },
      }),
    }),
  }),
})

export const {
  useCreateBookingMutation,
  useGetBookingsQuery,
  useLazyGetBookingPaymentLinkQuery,
  usePutBookingMutation,
} = bookingApi
